"use client";

import useSWR from "swr";

import { useAccess } from "@/lib/hooks/use-access";
import { swrFetcher } from "@/lib/hooks/fetch-client";
import { useEarlybird } from "@/lib/hooks/use-earlybird";
import { useSettings } from "@/lib/hooks/use-settings";
import { getCookie } from "cookies-next";

interface SaleWrapperProps {
	children?: React.ReactNode;
	cta?: React.ReactNode;
	earlybirdCta?: React.ReactNode;
	monthlyPaidCta?: React.ReactNode;
	yearlyPaidCta?: React.ReactNode;
	lifetimePaidCta?: React.ReactNode;
}

export default function SaleWrapper({
	children,
	cta,
	earlybirdCta,
	monthlyPaidCta,
	yearlyPaidCta,
	lifetimePaidCta,
}: SaleWrapperProps) {
	const { settings } = useSettings();
	const token = getCookie("token");
	const { data: subscription } = useSWR(token ? ["/v1/subscription", token] : null, swrFetcher);
	const isEnterpriseUser = subscription?.plan?.name?.includes("Enterprise");

	const hasAccess = useAccess(["study_summaries", "examine_database", "supplement_guides"]);

	const isSales = settings?.sale && hasAccess;
	const isEarlybird = useEarlybird();

	if (isEarlybird && earlybirdCta) {
		return earlybirdCta;
	}

	if (isSales && subscription?.plan?.name === "Lifetime" && lifetimePaidCta) {
		return lifetimePaidCta;
	}

	if (isSales && subscription?.plan?.name === "Yearly" && yearlyPaidCta) {
		return yearlyPaidCta;
	}

	if (isSales && subscription?.plan?.name === "Monthly" && monthlyPaidCta) {
		return monthlyPaidCta;
	}

	if (!settings?.sale || hasAccess || isEnterpriseUser) {
		return cta || null;
	}

	return children;
}
