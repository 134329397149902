import { format, formatDistance, formatRelative, subDays } from "date-fns";

const startDate = new Date(1723723200 * 1000);
const endDate = (formatString: string = "MMMM d") =>
	format(new Date(1723723200 * 1000), formatString);

const percentOff: string = "27%";
const salesType: string = "summer sale";

export { startDate, endDate, percentOff, salesType };
