import useProduct from "@/lib/hooks/use-product";

const useEarlybird = () => {
	const { product } = useProduct();

	let earlybird = false;
	if (product && product.plans.length) {
		product.plans.forEach((plan) => {
			const discountsArray = Array.isArray(plan.discounts) ? plan.discounts : [plan.discounts];
			discountsArray.forEach((discount) => {
				if (discount && discount.code === "earlybird") {
					earlybird = true;
				}
			});
		});
	}

	return earlybird;
};

const useDiscountCode = ({ code }: { code: string }) => {
	const { product } = useProduct();

	let passed = false;

	if (product && product.plans.length) {
		product.plans.forEach((plan) => {
			const discountsArray = Array.isArray(plan.discounts) ? plan.discounts : [plan.discounts];
			discountsArray.forEach((discount) => {
				if (discount && discount.code === code) {
					passed = true;
				}
			});
		});
	}

	return passed;
};

export { useEarlybird, useDiscountCode };
