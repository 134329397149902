"use client";

import Link from "next/link";

import { cn } from "@/lib/utils";
import { percentOff, salesType } from "@/lib/data/sales";
import { trackAdClick, trackSales } from "@/lib/analytics-tracking";
import { FreeTrialWrapper } from "@/components/free-trial-wrapper";
import SaleWrapper from "@/components/sale-wrapper";
import { Button } from "@/components/ui/button";
import { LoginLink } from "@/components/login-link";

interface ADProps {
	location?: string;
	label?: string;
	ad_element?: string;
	trackFor?: "trial" | "sale";
}
interface PlusLinkProps {
	trackDetails?: ADProps;
}

const PlusLink = ({ trackDetails }: PlusLinkProps) => {
	return (
		<ExaminePlusLink trackDetails={trackDetails}>
			<span className="font-bold text-examine-purple-400 hover:underline">Examine+</span>
		</ExaminePlusLink>
	);
};

interface ExaminePlusLinkProps extends React.HTMLAttributes<HTMLElement> {
	trackDetails?: ADProps;
	href?: string;
}

const ExaminePlusLink = ({
	children,
	className,
	trackDetails,
	href = "/plus/",
}: ExaminePlusLinkProps) => {
	const track = () => {
		if (!trackDetails) return;

		if (trackDetails?.trackFor === "sale") {
			trackSales({
				...trackDetails,
				location: trackDetails?.location,
				label: trackDetails?.label,
			});

			return;
		}

		trackAdClick({
			...trackDetails,
			location: trackDetails?.location,
			label: trackDetails?.label,
			//ad_element: trackDetails?.ad_element,
		});
	};

	return (
		<Link href={href} onClick={() => track()} className={className}>
			{children}
		</Link>
	);
};

interface CTATextProps {
	plus: React.ReactNode;
	trialText?: string;
}

const CTAText = ({ trialText, plus }: CTATextProps) => {
	return (
		<SaleWrapper
			cta={
				<FreeTrialWrapper cta={<>Get {plus} </>}>
					Try {plus} {trialText || "for free"} to
				</FreeTrialWrapper>
			}
		>
			Get {plus} discounted during our {salesType}! Sign up now to
		</SaleWrapper>
	);
};

interface OverlayCtaProps extends React.HTMLAttributes<HTMLElement> {
	location?: string;
	trackDetails?: ADProps;
}

const OverlayCta = ({ className, trackDetails }: OverlayCtaProps) => {
	return (
		<p className={cn("pointer-events-auto", className)}>
			<b>Unlocked for Examine+ members</b> (
			<LoginLink className="text-primary !underline">
				Sign in
			</LoginLink>
			)
			<br />
			<br />
			<SaleWrapper
				cta={
					<ExaminePlusLink
						trackDetails={{
							...trackDetails,
							ad_element: "Try Examine+ now, risk-free!",
						}}
					>
						<div className="text-primary !underline">
							<FreeTrialWrapper cta={"Get started with Examine+ today."}>
								Try Examine+ now, risk-free!
							</FreeTrialWrapper>
						</div>
					</ExaminePlusLink>
				}
			>
				<ExaminePlusLink
					trackDetails={{
						...trackDetails,
						trackFor: "sale",
						label: "Get started with Examine+ today.",
					}}
				>
					<div className="text-primary !underline">Get started with Examine+ today.</div>
				</ExaminePlusLink>
			</SaleWrapper>
		</p>
	);
};

interface WhatWorksCtaProps extends React.HTMLAttributes<HTMLElement> {
	trackDetails?: ADProps;
}

const WhatWorksCta = ({ className, trackDetails }: WhatWorksCtaProps) => {
	return (
		<div
			className={cn(
				"mx-auto my-5 flex flex-col items-center gap-3 rounded-md bg-primary p-5 text-white md:w-fit md:flex-row md:gap-6 md:px-15",
				className
			)}
		>
			<div>
				<div className="text-lg font-bold">What works and what doesn&apos;t?</div>
				<p className="text-small">Unlock the full potential of Examine</p>
			</div>
			<ExaminePlusLink
				trackDetails={{
					...trackDetails,
					ad_element: "What works and what doesn't?",
				}}
			>
				<div className="rounded-full bg-yellow-400 px-5 py-1 font-semibold text-black">
					<FreeTrialWrapper cta={"Get started"}>Try risk-free</FreeTrialWrapper>
				</div>
			</ExaminePlusLink>
		</div>
	);
};

interface FlashSaleCTAProps extends React.HTMLAttributes<HTMLElement> {}

const FlashSaleCTA = ({ className }: FlashSaleCTAProps) => {
	return (
		<div
			className={cn(
				"mt-1 flex flex-col items-center justify-stretch gap-y-1 text-center sm:gap-y-2",
				className
			)}
		>
			<Button variant="premium" className="sm:text-lg">
				FLASH SALE: Over {percentOff} off on Examine+
			</Button>
			<p className="text-base">
				<strong>Unconditional 60-day money-back guarantee</strong>
			</p>
		</div>
	);
};

export { OverlayCta, CTAText, PlusLink, WhatWorksCta, ExaminePlusLink, FlashSaleCTA };
