"use client";

import * as React from "react";
import { getCookie } from "cookies-next";

import { access as ACCESS, canAccess } from "@/lib/utils/index";
import { useUser } from "@/lib/hooks/auth";
import { ClientOnly } from "@/components/client-only";
import { useFeatures } from "@/lib/hooks/use-features";

interface AccessCheckProps extends React.HTMLAttributes<HTMLElement> {
	feature?: (
		| "all"
		| "examine_database"
		| "study_summaries"
		| "supplement_guides"
		| "deep_dives_archive"
		| "examine_gpt"
	)[];
	cta?: React.ReactNode;
	loggedIn?: boolean;
	featureCheck?: "all" | "any";
}

const AccessCheck = ({
	children,
	feature,
	cta,
	loggedIn,
	featureCheck = "all",
}: AccessCheckProps) => {
	const token = getCookie("token");
	const { data: userFeatures } = useFeatures();
	const { user } = useUser();
	const [hasAccess, setHasAccess] = React.useState(false);

	React.useEffect(() => {
		if (Array.isArray(feature)) {
			let access = true;
			feature.forEach((item) => {
				if (!canAccess(ACCESS[item], userFeatures)) {
					access = false;
				}
			});
			setHasAccess(access);

			if (featureCheck === "any") {
				access = false;
				feature.forEach((item) => {
					if (canAccess(ACCESS[item], userFeatures)) {
						access = true;
					}
				});
				setHasAccess(access);
			}
		}
	}, [feature, featureCheck, userFeatures]);

	if (loggedIn && token) {
		return <ClientOnly>{cta || null}</ClientOnly>;
	}

	if (user?.gpt && feature?.includes("examine_gpt")) {
		return <ClientOnly>{cta || null}</ClientOnly>;
	}

	return hasAccess ? <ClientOnly>{cta}</ClientOnly> : <ClientOnly>{children}</ClientOnly>;
};

export { AccessCheck };
