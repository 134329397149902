import * as React from "react";
import { cva, type VariantProps } from "class-variance-authority";

import { cn } from "@/lib/utils";

const badgeVariants = cva(
	"inline-flex items-center rounded-full font-sans border px-2.5 py-0.5 font-semibold transition-colors focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2",
	{
		variants: {
			variant: {
				default: "border-transparent bg-primary text-white hover:bg-primary/80",
				secondary:
					"border-transparent bg-secondary text-secondary-foreground hover:bg-secondary/80",
				destructive:
					"border-transparent bg-destructive text-destructive-foreground hover:bg-destructive/80",
				premium: "bg-premium text-primary border-transparent hover:bg-premium/80",
				outline: "text-foreground",
				cyan: "border-transparent bg-examine-green-500 text-white hover:bg-primary",
			},
			size: {
				xs: "text-xs",
				sm: "text-sm",
				md: "text-base",
			},
		},
		defaultVariants: {
			variant: "default",
			size: "sm",
		},
	}
);

export interface BadgeProps
	extends React.HTMLAttributes<HTMLDivElement>,
		VariantProps<typeof badgeVariants> {}

function Badge({ className, variant, size, ...props }: BadgeProps) {
	return (
		<div className="inline items-center">
			<div className={cn(badgeVariants({ variant, size }), className)} {...props} />
		</div>
	);
}

export { Badge, badgeVariants };
