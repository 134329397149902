"use client";

import useSWR from "swr";

import { Feature } from "@/types/index";
import { swrFetcher } from "@/lib/hooks/fetch-client";
import { getCookie } from "cookies-next";

export const useFeatures = () => {
	const token = getCookie("token");
	return useSWR<Feature[]>(["/v1/subscription/features", token], swrFetcher, {
		revalidateIfStale: false,
		refreshInterval: 1000 * 60 * 5,
	});
};
