"use client";

import * as React from "react";

import { useSubscription } from "@/lib/hooks/use-subscription";
import { useSettings } from "@/lib/hooks/use-settings";

interface FreeTrialWrapperProps extends React.HTMLAttributes<HTMLDivElement> {
	fallback?: React.ReactNode;
	cta?: any;
}

type CookieStore = {
	get: (name: string) => Promise<any>;
};

function useEzProxy() {
	const [ezProxy, setEzProxy] = React.useState();

	React.useEffect(() => {
		async function getEzProxyCookie() {
			if ("cookieStore" in window) {
				const ezProxy = await (window.cookieStore as CookieStore).get("ezproxy");
				setEzProxy(ezProxy);
			}
		}

		getEzProxyCookie();
	}, []);

	return ezProxy;
}

export function FreeTrialWrapper({ children, fallback = null, cta = null }: FreeTrialWrapperProps) {
	const ezProxy = useEzProxy();
	const { settings, isLoading: isLoadingSettings } = useSettings();
	const { subscription, isLoading: isLoadingSubscription } = useSubscription();

	if (ezProxy) {
		console.log({ ezProxy, settings, subscription, isLoadingSettings, isLoadingSubscription });
	}

	const isLoading = isLoadingSettings || isLoadingSubscription;

	if (isLoading) {
		return fallback;
	}

	if (subscription?.is_active || !settings?.trial) {
		return cta;
	}

	return children;
}
