"use client";

import { access, canAccess } from "@/lib/utils/index";
import { useFeatures } from "@/lib/hooks/use-features";

type AccessType = keyof typeof access;

export function useAccess(accessTypes: AccessType | AccessType[]) {
	const { data: features } = useFeatures();

	if (Array.isArray(accessTypes)) {
		return accessTypes.every((type) => canAccess(access[type], features));
	}

	return canAccess(access[accessTypes], features);
}
